
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Protected from './components/pages/OtherPages/Protected';
import Home from './components/pages/home/Home';
import AboutJkPaper from './components/pages/OtherPages/AboutJkPaper';
import AboutJkBond from './components/pages/OtherPages/AboutJkBond';
import Contact from './components/pages/OtherPages/Contact';
import Dashboard from './components/pages/dashboard/Dashboard';

// sales
import Registration from './components/pages/Sales/Registration';
import ApproveMember from './components/pages/Sales/ApproveMember';


// reports
import Enrollment from './components/pages/Reports/Enrollment';
import Redemption from './components/pages/Reports/Redemption';
import QrTransaction from './components/pages/Reports/QrTransaction';
import PointsReport from './components/pages/Reports/PointsReport';
import LandingPage from './components/pages/LandingPage/LandingPage';
function App() {
  localStorage.setItem('language', 'en')
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about_jk_paper' element={<AboutJkPaper />}></Route>
          <Route path='/about_jk_bond_forever' element={<AboutJkBond />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/dashboard' element={<Protected Cmp={Dashboard} requiredRoles={['RO', 'HO']} />}></Route>
          {/* <Route path='/landing_page' element={<LandingPage />}></Route> */}
          <Route path='/Home/landing' element={<LandingPage />}></Route>

          {/* sales */}
          <Route path='/registration' element={<Registration />}></Route>
          <Route path='/approve_member' element={<Protected Cmp={ApproveMember} requiredRoles={['RO', 'HO']} />}></Route>


          {/* Reports routes */}
          <Route path='/enrollment_report' element={<Protected Cmp={Enrollment} requiredRoles={['RO', 'HO']} />}></Route>
          <Route path='/redemption_report' element={<Protected Cmp={Redemption} requiredRoles={['RO', 'HO']} />}></Route>
          <Route path='/QR_transaction_report' element={<Protected Cmp={QrTransaction} requiredRoles={['RO', 'HO']} />}></Route>
          <Route path='/points_report' element={<Protected Cmp={PointsReport} requiredRoles={['RO', 'HO']} />}></Route>

        </Routes >
      </BrowserRouter >
    </>
  );
}

export default App;
