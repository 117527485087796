import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

export default function AboutJkBond() {
    return (
        <>
            <Header />
            <section className="blog-area pt182 min570">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-banner-title">
                                            <h4 className="text-center cntcth4"><span>About JK Bond Forever</span></h4>
                                        </div>
                                    </div>
                                </div>


                                <div className="row clearfix">
                                    <div className="col-md-12 col-xs-12 aboutv">
                                        <p> The “Bond Forever” program is an attempt by JK Paper to reach out to Screen printers across the country. In the printing paper value chain, the screen printers hold a key position in terms of mass utilizing Bond paper for their printing activities, through our program we can help them on understanding the benefits of using JK Paper products and reward their Loyalty. Through this program we seek to build a relationship with  Screen printers and encourage them to use/promote JK Paper products.
                                            <br /> Members will earn points each time they scan a QR code on the Eligible JK Paper product
                                            packaging. These points will be redeemable in the form of Paytm transfer and UPI transfer and other exciting offers.
                                            <br />
                                            Members will also be invited for special events and privileges under the program.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
